
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="">

			<!-- <form @submit.prevent="addOrderItems" autocomplete="off">
						<v-layout row wrap>

							<v-flex xs12 lg2 xl2 md3 sm4>
								<v-text-field v-model="order_items.order_sku_code" type="text" :label="$store.getters.language.data.order_items.order_sku_code" dense class="mx-1" filled outlined required>
								</v-text-field>
							</v-flex>

							<v-flex xs12 lg2 xl2 md3 sm4>
								<v-text-field v-model="order_items.order_item_price" type="text" :label="$store.getters.language.data.order_items.order_item_price" dense class="mx-1" filled outlined required>
								</v-text-field>
							</v-flex>

							<v-flex xs12 lg2 xl2 md3 sm4>
								<v-select class="mx-1" clearable :items="['pending','arrived','out of stock','stock']" v-model="order_items.order_item_status" dense filled outlined item-text="order_item_status" item-value="order_item_status" :return-object="false" :label="$store.getters.language.data.order_items.order_item_status">
								</v-select>
							</v-flex>

							<v-flex xs12 lg2 xl2 md3 sm4>
								<v-text-field v-model="order_items.order_item_quantity" type="number" :label="$store.getters.language.data.order_items.order_item_quantity" dense class="mx-1" filled outlined required>
								</v-text-field>
							</v-flex>

							<v-flex xs12 lg2 xl2 md3 sm4>
								<v-text-field v-model="order_items.order_item_note" type="textarea" :label="$store.getters.language.data.order_items.order_item_note" dense class="mx-1" filled outlined>
								</v-text-field>
							</v-flex>

							<v-flex xs12 lg2 xl2 md3 sm4>
								<v-text-field v-model="order_items.order_item_link" type="text" :label="$store.getters.language.data.order_items.order_item_link" dense class="mx-1" filled outlined>
								</v-text-field>
							</v-flex>

							<v-flex xs12 lg2 xl2 md3 sm4>
								<v-select class="mx-1" clearable :items="orders" v-model="order_items.order_id" dense filled outlined item-text="order_id" item-value="order_id" :return-object="false" :label="$store.getters.language.data.orders.order_id">
								</v-select>
							</v-flex>

							<v-flex xs12 lg2 xl2 md3 sm4>
								<v-select class="mx-1" clearable :items="users" v-model="order_items.user_id" dense filled outlined item-text="user_id" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_id">
								</v-select>
							</v-flex>

							<v-flex xs12 lg2 xl2 md2 sm4>
								<v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.order_items.add_btn}}</v-btn>
							</v-flex>
						</v-layout>
			</form> -->
			<v-layout row wrap mt-5>
				<v-flex xs12>
					<!-- add a search button -->
					<!-- <h1 class="ma-3">Pending Items </h1> -->
				
					<h2 class="mb-3">Total Pending Price : ${{(total_pending_price[0].total_pending_price|| 0).toLocaleString()}}</h2>
					<v-text-field v-model="search" class="ma-3" append-icon="mdi-magnify" label="search" dense outlined hide-details></v-text-field>
					<v-card>
						<v-card-text>
							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="order_item_id">
								<template v-slot:[`item.order_item_price`]="{ item }">
									<div>
										$ {{ item.order_item_price }}
									</div>
								</template>
								<!-- template v-odel for order_item_status -->
								<template v-slot:[`item.order_item_status`]="{ item }">
									<div>
										<v-chip v-if="item.order_item_status == 'pending'" color="orange" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'packed'" color="green" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'incomplete'" color="red" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'purchased'" color="blue" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'soldout'" color="black" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'postponed'" color="black" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'delivered'" color="gray" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'soldout_told'" color="pink accent-1" dark>{{ item.order_item_status }}</v-chip>

									</div>
								</template>
								<!-- create a v-select for the order_item_status -->
								<!-- template for the order_item_link on click go to the link -->
								<template v-slot:[`item.order_item_link`]="{ item }">
									<div>
										<a :href="item.order_item_link" target="_blank">
											<v-tooltip bottom>
												<template v-slot:activator="{ on }">
													<v-icon v-on="on" v-if="item.order_item_link != null">mdi-link</v-icon>
													<v-icon v-if="item.order_item_link == null" color="red" v-on="on">mdi-link</v-icon>
												</template>
												<span>{{ item.order_item_link }}</span>
											</v-tooltip>

										</a>
									</div>
								</template>
								<!-- change the color of order_item_quantity to red -->
								<!-- <template v-slot:[`item.order_item_quantity`]="{ item }">
									<div>
										<v-chip v-if="item.order_item_quantity > 1" color="red" dark>{{ item.order_item_quantity }}</v-chip>
										<v-chip v-else color="green" dark>{{ item.order_item_quantity }}</v-chip>
									</div>
								</template> -->

								<!-- order_item_date format date and time -->
								<template v-slot:[`item.order_item_date`]="{ item }">
									<div>
										{{ new Date(item.order_item_date).toISOString().split('T')[0]}} {{ new Date(item.order_item_date).toISOString().split('T')[1].split('.')[0]}}
									</div>
								</template>

								<template v-slot:[`item.order_item_id`]="{ item }">
									<div>
										<!-- <v-btn icon :to="'/order_items-list/'+item.order_item_id" color="teal" class="mx-1">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn> -->
										<!-- <v-btn color="error" icon class="mx-1" @click="selectOrderItems(item)" disabled>
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn> -->
										<v-btn color="red" @click="show_soldout_box(item)" class="mx-1" icon><v-icon>mdi-cart-off</v-icon></v-btn>
										<v-btn color="success" @click="updateOrderItemStatusPurchase(item, 'purchased')" class="mx-1" icon><v-icon>mdi-cart-arrow-right</v-icon></v-btn>
										<!-- <v-btn color="red" @click="updateOrderItemStatusPurchase(item, 'incomplete')" class="mx-1" icon><v-icon>mdi-cart-arrow-right</v-icon></v-btn> -->
										<v-btn color="primary" @click="selectOrderItems(item)" class="mx-1" icon><v-icon>mdi-bag-personal</v-icon></v-btn>
										<!-- btn on click sshow showUpdateDialog -->
										<v-btn color="red" @click="selectOrderItemsForUpdate(item)" class="mx-1" icon><v-icon>mdi-note-alert-outline
											</v-icon></v-btn>
									</div>
								</template>

							</v-data-table>
						</v-card-text>

						<!-- <v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteOrderItemsList">{{$store.getters.language.data.order_items.delete_list_btn}}</v-btn>
						</v-card-actions> -->

					</v-card>
                    	<form @submit="searchReadOrderItems" autocomplete="off" class="mt-8">
						<v-layout row wrap>
							<v-flex xs12 lg4 xl4 md4 sm12>

								<v-autocomplete v-model="search_pending.customer_username" :items="customers" item-text="customer_username" item-value="customer_username" label="Customer" dense outlined hide-details class="ma-3" clearable></v-autocomplete>
							</v-flex>
                            <v-flex xs12 lg4 xl4 md4 sm12>
                                <v-btn color="primary" @click="readCustomers" class="ma-3">Get Customers</v-btn>
                            </v-flex>
							<v-flex xs12 lg6 xl6 md6 sm12>
								<!-- select quantity 50,100 -->
								<!-- <v-select v-model="search_pending.item_quantity" :items="[50,100]"  label="Quantity" dense outlined hide-details class="ma-3" clearable></v-select> -->
								<!-- make it search_pending.item_quantity btn two btn one 50 second 100 -->
								<v-btn-toggle v-model="search_pending.item_quantity" class="ma-3">
									<v-btn :class="{ 'lprimary': search_pending.item_quantity == 50, }" class="mx-3" value="50" width="100%">50</v-btn>
									<v-btn :class="{ 'lprimary': search_pending.item_quantity == 100 }" class="mx-3" value="100" width="100%">100</v-btn>
								</v-btn-toggle>
							</v-flex>
							<v-flex xs12 lg4 xl4 md4 sm12>
								<v-btn color="primary" type="submit" class="ma-3">Search</v-btn>
							</v-flex>

						</v-layout>
					</form>
				</v-flex>
			</v-layout>
		</v-container>
		<!-- create a dialog for showUpdateDialog -->
         <!-- sold_out_dialog -->
                <v-dialog v-model="sold_out_dialog" >
                    <v-card>
                        <v-card-title>
                            <h3>Are you sure you want to mark this item as sold out?</h3>
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="updateOrderItemStatusPurchase(sold_out_item, 'soldout')">Yes</v-btn>
                            <v-btn color="error" @click="sold_out_dialog = false">No</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>


		<v-dialog v-model="showUpdateDialog">
			<v-card>
				<v-card-title>

					Sku Code : {{item_update.order_sku_code}}

				</v-card-title>
				<v-card-text>
					<v-layout row wrap>

						<!-- <v-flex xs12>
							<v-text-field v-model="item_update.order_item_id" label="Order Item Price" type="number" outlined dense></v-text-field>
						</v-flex>
						<v-flex xs12>
							<v-text-field v-model="item_update.order_item_status" label="Order Item Link" type="text" outlined dense></v-text-field>
						</v-flex> -->
						<!-- create radio buttons for status -->
						<!-- v-flex for note -->
						<v-flex xs12 class="my-4">
							<v-text-field v-model="item_update.order_item_note" label="Order Item Note" type="text" outlined dense></v-text-field>
						</v-flex>

					</v-layout>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="updateOrderItemStatusPurchase(item_update, 'incomplete')">Update</v-btn>
					<v-btn color="error" @click="showUpdateDialog = false">Cancel</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showBagDialog" v-if="order.length > 0">
			<v-card>
				<v-card-title>
					Order Bag ( {{$store.getters.language.data.orders.order_date}}: {{new Date(order[0].order_date).toLocaleString()}} )
					<v-spacer></v-spacer>
					<v-btn color="success" class="elevation-0" disabled v-if="order_items_bag.length > 0 && order_items_bag.every(item => item.order_item_status != 'packed')">
						<v-icon>mdi-bag-carry-on-check</v-icon> Deliver Bag
					</v-btn>
					<v-btn color="success" class="elevation-0" @click="addPackedOrders" v-else>
						Deliver Bag
					</v-btn>

				</v-card-title>
				<v-card-text>
					<!-- {{order}} -->
					<!--  show the order[0] infromations in a card -->
					<v-layout row wrap class="ma-3">
						<v-flex xs12 lg2 xl2 md3 sm4>
							<p class="font-weight-bold">{{$store.getters.language.data.customers.customer_username}}: {{order[0].customer_username}}</p>
						</v-flex>
						<v-flex xs12 lg2 xl2 md3 sm4>
							<p class="font-weight-bold"> Entered By : {{order[0].user_username}}</p>
						</v-flex>
						<v-flex xs12 lg2 xl2 md3 sm4>
							<p class="font-weight-bold"> {{$store.getters.language.data.orders.order_shipping_price}}: ${{order[0].order_shipping_price}}</p>
						</v-flex>
						<v-flex xs12 lg3 xl3 md4 sm5 v-if='order[0].order_note != null'>
							<p class="font-weight-bold"> {{$store.getters.language.data.orders.order_note}}: {{order[0].order_note}}</p>
						</v-flex>
						<v-flex xs12 lg3 xl3 md4 sm5>
							<p class="font-weight-bold"> Total Packed Items : ${{total_bag_price}}</p>
						</v-flex>
						<v-flex xs12 lg3 xl3 md4 sm5>
							<p class="font-weight-bold"> Total Shipping Price : ${{total_shipping_price}}</p>
						</v-flex>
						<v-flex xs12 lg3 xl3 md4 sm5>
							<p class="font-weight-bold"> Total Price : ${{total_price}}</p>
						</v-flex>
						<!-- show paregraph if it was packed otherwise show the button -->
						<v-flex xs12 lg2 xl2 md3 sm4>
							<p v-if='order[0].order_status == "packed"' style="color:green" class="font-weight-bold">{{$store.getters.language.data.orders.order_status}}: {{order[0].order_status}}</p>
							<p v-if='order[0].order_status == "unpacked"' style="color:red" class="font-weight-bold">{{$store.getters.language.data.orders.order_status}}: {{order[0].order_status}}</p>
						</v-flex>

					</v-layout>

					<!-- <div class="table-container">
    <table>
      <thead>
        <tr>
          <th>#</th>
          <th>SKU Code</th>
          <th>Price</th>
          <th>Qty</th>
          <th>Total Price</th>
          <th>Status</th>
          <th>Note</th>
          <th>Order ID</th>
          <th>Order Status</th>
          <th>Order Note</th>
          <th>Date</th>
          <th>User Username</th>
          <th>Order Item Username</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item,index in order_items_bag" :key="item.order_item_id">
            <td>{{ ++index }}</td>
          <td>{{ item.order_sku_code }}</td>
          <td>$ {{ item.order_item_price }}</td>
          <td>{{ item.order_item_quantity }}</td>
          <td>$ {{ item.order_item_price*item.order_item_quantity }}</td>
          <td>{{ item.order_item_status }}</td>
          <td>{{ item.order_item_note }}</td>
          <td>{{ item.order_id }}</td>
          <td>{{ item.order_status }}</td>
          <td>{{ item.order_note }}</td>
          <td>{{ new Date(item.order_item_date).toISOString().split('T')[0]}} {{ new Date(item.order_item_date).toISOString().split('T')[1].split('.')[0]}}</td>
          <td>{{ item.user_username }}</td>
          <td>{{ item.order_item_username }}</td>
        </tr>
      </tbody>
    </table>
  </div> -->

					<!-- show the order_items_bag in a a data table  -->
					<v-data-table :headers="item_headers" :items="order_items_bag" :loading="loading" class="elevation-1" item-key="order_item_id">
						<template v-slot:[`item.order_item_price`]="{ item }">
							<div>
								$ {{ item.order_item_price }}
							</div>
						</template>
						<template v-slot:[`item.total_item_price`]="{ item }">
							<div>
								$ {{ item.total_item_price }}
							</div>
						</template>
						<template v-slot:[`item.order_item_status`]="{ item }">
							<div>
								<v-chip v-if="item.order_item_status == 'pending'" color="orange" dark>{{ item.order_item_status }}</v-chip>
								<v-chip v-if="item.order_item_status == 'packed'" color="green" dark>{{ item.order_item_status }}</v-chip>
								<v-chip v-if="item.order_item_status == 'incomplete'" color="red" dark>{{ item.order_item_status }}</v-chip>
								<v-chip v-if="item.order_item_status == 'purchased'" color="blue" dark>{{ item.order_item_status }}</v-chip>
								<v-chip v-if="item.order_item_status == 'soldout'" color="black" dark>{{ item.order_item_status }}</v-chip>
								<v-chip v-if="item.order_item_status == 'postponed'" color="gray" dark>{{ item.order_item_status }}</v-chip>
								<v-chip v-if="item.order_item_status == 'delivered'" color="gray" dark>{{ item.order_item_status }}</v-chip>
								<v-chip v-if="item.order_item_status == 'unarrived'" color="gray" dark>{{ item.order_item_status }}</v-chip>
								<v-chip v-if="item.order_item_status == 'soldout_told'" color="pink accent-1" dark>{{ item.order_item_status }}</v-chip>

							</div>
						</template>

						<template v-slot:[`item.order_item_link`]="{ item }">
							<div>
								<a :href="item.order_item_link" target="_blank">
									<v-tooltip bottom>
										<template v-slot:activator="{ on }">
											<v-icon v-on="on" v-if="item.order_item_link != null">mdi-link</v-icon>
											<v-icon v-if="item.order_item_link == null" color="red" v-on="on">mdi-link</v-icon>
										</template>
										<span>{{ item.order_item_link }}</span>
									</v-tooltip>

								</a>
							</div>
						</template>
						<template v-slot:[`item.order_item_date`]="{ item }">
							<div>
								{{ new Date(item.order_item_date).toISOString().split('T')[0]}} {{ new Date(item.order_item_date).toISOString().split('T')[1].split('.')[0]}}
							</div>
						</template>
					</v-data-table>

				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="showBagDialog = false">
						{{$store.getters.language.data.order_items.cancel_btn}}
					</v-btn>
					<v-btn color="success" class="elevation-0">
						Print
					</v-btn>

				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>

</template>
<script>
	import requests from '../../requests/order_items.request.js'
	import requestsOrder from '../../requests/orders.request.js'
	import requestsPackBag from '../../requests/packed_orders.request.js'
	import requestsPackedItems from '../../requests/packed_items.request.js'
    import requestsCus from '../../requests/customers.request.js'

	export default {
		data() {
			return {
				order_items: {},
				search_pending: {
					item_quantity: 50
				},
				total_bag_price: 0,
				total_shipping_price: 0,
				total_pending_price: 0,
				total_pending_price: [
					{
						total_pending_price: 0
					}
				],
				total_price: 0,
                sold_out_item: {},
                sold_out_dialog: false,
				search: '',
				loading: true,
				loading_btn: false,
				showBagDialog: false,
				showUpdateDialog: false,
				selected_rows: [],
                customers: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_order_items: {},
				order: {},
				order_items_bag: [],
				delete_dialog: false,
				item_update: {},
				headers: [


					{
						text: this.$store.getters.language.data.order_items.order_sku_code,
						align: 'start',
						sortable: true,
						value: 'order_sku_code',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_price,
						align: 'start',
						sortable: true,
						value: 'order_item_price',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_status,
						align: 'start',
						sortable: true,
						value: 'order_item_status',
					},
					// {
					// 	text: this.$store.getters.language.data.order_items.order_item_quantity,
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'order_item_quantity',
					// },
					// {
					// 	text: "Total Price",
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'total_item_price',
					// },
					{
						text: this.$store.getters.language.data.order_items.order_item_note,
						align: 'start',
						sortable: true,
						value: 'order_item_note',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_link,
						align: 'start',
						sortable: true,
						value: 'order_item_link',
					},
					{
						text: this.$store.getters.language.data.orders.order_id,
						align: 'start',
						sortable: true,
						value: 'order_id',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_date,
						align: 'start',
						sortable: true,
						value: 'order_item_date',
					},
					// {
					// 	text: this.$store.getters.language.data.users.user_username,
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'order_item_username',
					// },
					{
						text: this.$store.getters.language.data.customers.customer_username,
						align: 'start',
						sortable: true,
						value: 'customer_username',
					},
					{
						text: this.$store.getters.language.data.customers.customer_type,
						align: 'start',
						sortable: true,
						value: 'customer_type',
					},
					{
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'order_item_id',
					}
				],
				item_headers: [


					{
						text: this.$store.getters.language.data.order_items.order_sku_code,
						align: 'start',
						sortable: true,
						value: 'order_sku_code',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_price,
						align: 'start',
						sortable: true,
						value: 'order_item_price',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_quantity,
						align: 'start',
						sortable: true,
						value: 'order_item_quantity',
					},
					{
						text: "Total Price",
						align: 'start',
						sortable: true,
						value: 'total_item_price',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_status,
						align: 'start',
						sortable: true,
						value: 'order_item_status',
					},

					{
						text: this.$store.getters.language.data.order_items.order_item_link,
						align: 'start',
						sortable: true,
						value: 'order_item_link',
					},
					{
						text: this.$store.getters.language.data.orders.order_id,
						align: 'start',
						sortable: true,
						value: 'order_id',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_note,
						align: 'start',
						sortable: true,
						value: 'order_item_note',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_date,
						align: 'start',
						sortable: true,
						value: 'order_item_date',
					},
					// {
					// 	text: this.$store.getters.language.data.users.user_username,
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'order_item_username',
					// },
					// {
					// 	text: this.$store.getters.language.data.customers.customer_username,
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'customer_username',
					// },
					// {
					// 	text: this.$store.getters.language.data.actions,
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'order_item_id',
					// }
				],
			}
		},
		computed: {
			orders() {
				return this.$store.getters.orders_list
			},
			users() {
				return this.$store.getters.users_list
			},
			user() {
				return this.$store.getters.user
			},
			// customers() {
			// 	return this.$store.getters.customers_list
			// }

		},
		mounted() {
			this.searchReadOrderItems();
		},
		methods: {
            show_soldout_box(item) {
                this.sold_out_item = item
                this.sold_out_dialog = true
            },
            	readCustomers() {
				this.loading = true
				requestsCus.getAllCustomers().then(r => {
					if (r.status == 200) {
						this.customers = r.data.rows
						// this.blocked_numbers = r.data.blocked_numbers
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Customers',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Customers',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			addOrderItemsList(packed_order_id) {
				var packed_order_id = packed_order_id
				this.loading_btn = true
				// create a array object add all item_id in order_items_bag with the name of item_id
				let data = []
				for (let i = 0; i < this.order_items_bag.length; i++) {
					// if the order_item_staus is not packed then add it to the data array object
					if (this.order_items_bag[i].order_item_status == 'packed') {
						data.push({
							packed_order_id: packed_order_id,
							order_item_id: this.order_items_bag[i].order_item_id,
						})
					}
				}

				console.log(data)
				requestsPackedItems.createPackedItemsList({ list: data }).then(r => {
					if (r.status == 200) {
						this.updateStatusPackedItems()
						this.order_items = {}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'OrderItems Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add OrderItems',
							color: 'error'
						}
					}
					this.loading_btn = false
				})

			},
			addPackedOrders() {
				this.loading_btn = true
				let data = {
					order_id: this.order[0].order_id,
					packed_order_total_price: this.total_price,
					user_id: this.user.user_id,
					// packed_order_date: new Date().toISOString().slice(0, 19).replace('T', ' '),
				}
				requestsPackBag.createPackedOrders(data).then(r => {
					if (r.status == 200) {

						this.addOrderItemsList(r.data.new_data.packed_order_id)
						this.data = {}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'PackedOrders Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add PackedOrders',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			addOrderItems() {
				this.loading_btn = true
				requests.createOrderItems(this.order_items).then(r => {
					if (r.status == 200) {
						this.order_items = {}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'OrderItems Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add OrderItems',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deleteOrderItems(order_item_id) {
				requests.deleteOrderItems(order_item_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.order_item_id != order_item_id
						})
						this.snackbar = {
							value: true,
							text: 'OrderItems Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deleteOrderItemsList() {
				let ids = this.selected_rows.map(m => m.order_item_id)
				requests.deleteOrderItemsList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.order_item_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' OrderItems Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},
			searchReadOrderItems() {
				this.loading = true
				this.search_pending.order_item_status = "pending"
				requests.searchOrderItems(this.search_pending).then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.total_pending_price = r.data.total_pending_price
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read OrderItems',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read OrderItems',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			readOrderItems() {
				this.loading = true
				requests.reportOrderItems("order_item_status", "pending").then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.total_pending_price = r.data.total_pending_price
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read OrderItems',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read OrderItems',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},

			getAllItems() {
				this.loading = true
				requestsOrder.reportByOrderId("order_id", this.selected_order_items.order_id).then(r => {
					if (r.status == 200) {
						this.order = r.data.rows.order
						this.order_items_bag = r.data.rows.rows
						this.total_bag_price = r.data.rows.total_bag_price[0]['total_item_price']
						this.total_shipping_price = r.data.rows.total_bag_price[0]['total_shipping_price']
						this.total_price = r.data.rows.total_bag_price[0]['total_price']
						console.log(r.data.rows)
						this.loading = false
						this.showOrders = true
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Orders',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Orders',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},

			selectOrderItems(i) {
				this.selected_order_items = i
				this.showBagDialog = true
				this.getAllItems()
			},
			selectOrderItemsForUpdate(i) {
				// take a copy of i into item_update
				this.item_update = Object.assign({}, i)
				this.showUpdateDialog = true
			},
			updateOrderItemStatus() {
				this.loading_btn = true
				let data = {
					order_item_id: this.item_update.order_item_id,
					order_item_status: this.item_update.order_item_status,
					order_id: this.item_update.order_id,
					order_item_link: this.item_update.order_item_link,
					order_item_note: this.item_update.order_item_note,
					order_item_price: this.item_update.order_item_price,
					order_item_quantity: this.item_update.order_item_quantity,
					order_sku_code: this.item_update.order_sku_code,
					user_id: this.item_update.user_id,

				}
				// console.log(data)
				// console.log(this.item_update)
				// delete this.item.total_item_price
				// delete this.item_update.order_item_date
				// delete this.item_update.order_date
				// delete this.item_update.order_shipping_price
				// delete this.item_update.order_status
				// delete this.item_update.order_packing_date
				// delete this.item_update.order_total_price
				// delete this.item_update.order_note
				// delete this.item_update.user_username
				// delete this.item_update.customer_username
				// delete this.item_update.order_item_username
				// delete this.item_update.user_role

				requests.updateOrderItems(data.order_item_id, data).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.map(m => {
							if (m.order_item_id == this.item_update.order_item_id) {
								m.order_item_status = this.item_update.order_item_status
							}
							this.showUpdateDialog = false
							return m
						})
						this.snackbar = {
							value: true,
							text: 'OrderItem Updated',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to update OrderItem',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to update OrderItem',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading_btn = false
					})
			},
			packBag() {
				this.loading_btn = true
				console.log(this.order)
				let data = {
					order_id: this.order[0].order_id,
					order_status: "Packed",
					order_packing_date: this.order[0].order_packing_date,
					order_shipping_price: this.order[0].order_shipping_price,
					order_total_price: this.total_price,
					order_note: this.order[0].order_note,
					user_id: this.order[0].user_id,
					customer_id: this.order[0].customer_id,
					order_date: new Date(this.order[0].order_date).toISOString().slice(0, 19).replace('T', ' '),
					order_packing_date: new Date().toISOString().slice(0, 19).replace('T', ' '),
				}
				console.log(data)
				requestsOrder.updateOrders(data.order_id, data).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.map(m => {
							if (m.order_id == this.selected_order_items.order_id) {
								m.order_status = "Packed"
							}
							this.showBagDialog = false
							return m
						})
						this.snackbar = {
							value: true,
							text: 'Order Packed',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to pack Order',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to pack Order',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading_btn = false
					})
			},
			updateStatusPackedItems() {
				this.loading_btn = true

				let order_id = this.order[0].order_id

				requests.updateStatus(order_id).then(r => {
					if (r.status == 200) {
						this.readOrderItems()
						this.getAllItems()
						console.log("update status")
						this.snackbar = {
							value: true,
							text: 'Update Order Items',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to Update Order Items',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to pack Order',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading_btn = false
					})


			},
			updateOrderItemStatusPurchase(item, status) {
				this.loading_btn = true
				let newIteminfo = item
				let data = {
					order_item_id: newIteminfo.order_item_id,
					order_item_status: status,
					order_id: newIteminfo.order_id,
					order_item_link: newIteminfo.order_item_link,
					order_item_note: newIteminfo.order_item_note,
					order_item_price: newIteminfo.order_item_price,
					order_item_quantity: newIteminfo.order_item_quantity,
					order_sku_code: newIteminfo.order_sku_code,
					user_id: newIteminfo.user_id,


				}

				// console.log("====================================")
				// console.log(data)
				// console.log(this.item_update)
				// delete this.item.total_item_price
				// delete this.item_update.order_item_date
				// delete this.item_update.order_date
				// delete this.item_update.order_shipping_price
				// delete this.item_update.order_status
				// delete this.item_update.order_packing_date
				// delete this.item_update.order_total_price
				// delete this.item_update.order_note
				// delete this.item_update.user_username
				// delete this.item_update.customer_username
				// delete this.item_update.order_item_username
				// delete this.item_update.user_role

				requests.updateOrderItems(data.order_item_id, data).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.map(m => {
							if (m.order_item_id == this.item_update.order_item_id) {
								m.order_item_status = this.item_update.order_item_status
							}
                            this.sold_out_dialog = false
							this.showUpdateDialog = false
							return m
						})
						this.searchReadOrderItems()
						this.snackbar = {
							value: true,
							text: 'Item ' + status,
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail ',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to update OrderItem',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading_btn = false
					})
			},
		},
	}
</script>
<style scoped>
	table,
	th,
	td {
		border: 1px solid black;
		border-collapse: collapse;
		margin: 0 auto;
	}
	th,
	td {
		padding: 5px;
		text-align: left;
	}
	@media (max-width: 600px) {
		th,
		td {
			font-size: 12px;
			padding: 4px;
		}
	}
	.table-container {
		overflow-x: auto; /* Enable horizontal scrolling if needed */
	}
</style>
